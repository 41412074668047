<!--  -->

<template>
  <html style="padding:15px">
  
        <el-page-header @back="goSelect" content="查看信息" style="margin-bottom:20px">
        </el-page-header>
      
  <div class="shuaxin">
    <i @click="sfStartdhsj()"><img style="width:40px;height:40px;"
                                   src="/img/mapBuiding/shuaxin1.png"/></i>
  </div>

  <div class="shuaxin1" style="text-align:right;z-index: 9999">
    <i @click="sfStartzdtz()"><img style="width:40px;height:40px;"
                                   src="/img/mapBuiding/shuaxin1.png"/></i>
  </div>
<div style="position:absolute;bottom:0.5%;height:50px;width:100px;border-radius:0px 10px 30px 0;background-color:rgba(17,53,73,0.98);z-index:999"></div>
  <ShowBuidingInfo :dataForSon="dataForSon" ref="ShowBuidingInfo" :class="{submitWindowsViewClass:true, displayBlock:this.submitWindows,displayNone:!this.submitWindows,}"></ShowBuidingInfo>


  <div class="shuaxinbt" id ="shuaxin5" ref='shuaxin5' style="z-index: 9999">
    <div id="myChart5" style="width: 599px;height: 700px;"></div>
  </div>


  <div class="shell" id="shuaxin6" style="width: 300px;height: 700px;">
    <div class="activity" id="J_Activity">
    </div>
  </div>


  <div :if="greenText" class="shell" id="shuaxin7" :style="'width:' +greenText?'400':'0'+'px;height: 700px;'">
  </div>

<div>

      <el-dialog title="设备信息详情"  :visible.sync="deviceInfoDialogVisible" width="60%" @close="clearDeviceInfo">
        <el-form label-width="120px" :model="deviceInfo" class="item-form" >
          <!-- 动态 -->
            <el-card class="greenForm item-card" v-for="item in deviceValueInfoList" :class="item.value.indexOf('故障')!=-1&&item.value.indexOf('无故障')<0? 'yellow' :item.value.indexOf('报警')!=-1 || (item.value.indexOf('火警')!=-1 && item.value.indexOf('无火警')==-1 ) ? 'red' : item.value.indexOf('失联')!=-1 ? 'gery': 'green'">
               <b>{{item.id}}</b> <br>{{item.value}}
             </el-card>
            <!-- 静态 -->
            <el-card class="item-card">
               <b>设备ID</b> <br>{{deviceInfo.id}}
            </el-card>
            <el-card class="item-card">
               <b>设备类型</b> <br>{{deviceInfo.typeName}}
            </el-card>

            <el-card v-for="item in deviceInfo.device" class="item-card">
               <b>{{item.proName}}</b> <br>{{ item.proName == "绑定公司"?companyNameById[item.value] :item.proName == "所属系统"? systemNameById[item.value] : item.value }}
            </el-card>

        </el-form>
        <div slot="footer" class="dialog-footer"> 
            <el-button @click="toHistory" type="primary">历史记录</el-button>
            <el-button type="primary" @click="showVideo" :type="deviceInfo.videoUrl!=null?'success':'warning'">查看视频</el-button>
        </div>
    </el-dialog>

</div>
  <div id="map_container" ></div>



  </html>
</template>


<script>
import dataStorage from '@/../utils/dataStorage.js'
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import companySystem from "@/api/managementApi/CompanyInfo";
import jQuery from 'jquery'
  import $ from 'jquery'
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";

import mapBuidingApi from "@/api/mapBuidingApi/mapBuidingApi.js";
  import BaseData from "../../assets/config/BaseData";
  import ShowBuidingInfo from "@/views/mapBuiding/ShowBuidingInfo";

  export default {

    data () {
      return {
        ciIds:"",
        // 是否显示设备默认图标
        layerDeviceDisplay:false,
        deviceInfoMap:{},
        deviceInfoMap2:{},
        companyNameById: {},
        systemNameById: {},
        deviceInfo:[],
        baseURL:BaseData.baseUrl,
        iconBaseUrl:BaseData.baseUrl,
        ciIdsToNext  : "0",
        addedDeviceInfoQuery:{},
        // pingMuWidth : window.screen.width,
        //屏幕高度
        // pingMuHeight : window.screen.height,

        pingMuWidth : document.body.clientWidth,
        //屏幕高度
        pingMuHeight : document.body.clientHeight,

//      是否显示左上角绿色的字体
        greenText : false,
      //设备动态属性
      deviceValueInfoList: [],

        //deviceInfoDate 这个是设备在地图上的数据
        deviceInfoDate :[],

        //deviceInfoDate2 这个是设备在地图上的数据 报警和点击事件的调用数据
        deviceInfoDate2 :[],

        //deviceiconUrlDate 这个是设备在地图上的图标
        deviceiconUrlDate :[],


        //datajs_house 这个是房子 水 路 和 草地的数据
        datajs_house_big :[],
        //roadLineDate 这个是路线的数据
        roadLineDate :[],

        //fireworksFountainDate 这个是喷泉的数据
        fireworksFountainDate :[],
        //radarDate 这个是雷达的数据
        radarDate :[],


        //visualAngleData 这个是视角的数据
        visualAngleData :[],
        //这个是视角的一个对象
        animation:{},
        sjmbzoom : 18,//视角动画的高度
        sjmbtilt : 70,//视角动画的角度
        sjztime : 50,//这个是视角的总时间 单位秒
        dhystime : 1,//动画延时开始时间 单位秒

        //这个是是否暂停自动跳转 的视角
        startzdtz :false,
        //这个是当前视角动画的状态
        DhSjStatus : false,

//    这个存放的是报警建筑物的视图集合
        bjJzwSz : {},

        //这个存放的是所有设备关联的建筑物id
        buildingIdByDeviceId : {},
        //这个存放的是所有建筑物的id 和 建筑物的信息
        buildingInfoBybuildingId : {},
        //这个是打字效果的数组
        strS : ["Please wait for data initialization。。。。。。",
          "The history of alarm data will be displayed here:",
          "History of wireless smoke alarm",
          "Fire alarm history monitoring record",
          "History of dynamic environment monitoring",],


//   视角跳转sjtzArr 的数组
        sjtzArr:[],

        //定是函数 存储 销毁要用到
        thisInterval:{},
        zxffj : 1,
      //设备信息详情弹框标志位
      deviceInfoDialogVisible: false,
        layerDevice2:{},

        map :{},
        view:{},
        view2:{},
        view3:{},
        view4:{},
        //发光处理通道
        bright :{},

        dqStr : 0,
        //这个参数是当前建筑物的视角设备id
        thisSjId : "init",

        //  这个参数是存放报警数据的
        alamtextHtml:{},

        hsNum :0,
        shuaxin7Html : "",
        divIds : {},
        submitWindows:false,
        dataForSon:{
          div:""
        },



      };
    },


    components: {
      ShowBuidingInfo

    },

    computed: {

      
    },

  created() {
    this.getCompanyInfo();
    this.jurisSysName();

    if(dataStorage.getBaseData("mapbuidingrelode")==0 || dataStorage.getBaseData("mapbuidingrelode") == null){
      window.location.reload()
      dataStorage.setBaseData("mapbuidingrelode", 1)
    }else{
      dataStorage.setBaseData("mapbuidingrelode", 0)

    }
    
  },
    mounted(){
      
    },




    methods: {
              //根据设别类型id删除设备类型信息
        handleRemove(id) {
          this.deleteDevice(id);
        },

        deleteDevice(id){
          let that = this
          mapBuidingApi.deleteDeviceLoLa(id).then((res)=>{
            location. reload()

          });

        },

      mountedThis(){
              let that = this;
      that.$emit("routerIsmapBuidingHomeTFFunction",true)
      that.getBuildingInfo(that.ciIds);
// <!--加载div位置-->

      document.getElementById('shuaxin5').style.marginTop = ' 0.5%';
      document.getElementById('shuaxin5').style.marginLeft = '50px';
      document.getElementById('myChart5').style.height = that.pingMuHeight*0.06+"px"

      document.getElementById('shuaxin6').style.marginTop =0.8*that.pingMuHeight +"px"
      document.getElementById('shuaxin6').style.marginLeft =0.01*that.pingMuWidth+"px"
      document.getElementById('shuaxin6').style.height = 0.15*that.pingMuHeight + "px"
      document.getElementById('shuaxin6').style.width = (that.pingMuWidth-700)+"px"


if(that.greenText){
        document.getElementById('shuaxin7').style.marginTop =0.1*that.pingMuHeight+"px"
      document.getElementById('shuaxin7').style.marginLeft = 0.01*that.pingMuWidth+"px"
      document.getElementById('shuaxin7').style.height = that.pingMuHeight*0.5+"px"

      that.hsNum = that.pingMuHeight*0.60/60
      that.shuaxin7Html = ""
      that.divIds = {}
      for(var i=0;i< that.hsNum;i++ ){
        that.shuaxin7Html = that.shuaxin7Html + "<div class='shell2' style=' color: #00ff00;font-size: 15px;' id='code"+i+"'></div>"
        that.divIds["code"+i] = 0
      }
      document.getElementById('shuaxin7').innerHTML = that.shuaxin7Html;

}



      // that.getUpInfo1(1);


      //发光处理通道
      that.bright = new mapvgl.BrightEffect({
        threshold: 0.21,
        blurSize: 0.1,
        clarity: 0.9
      });


      that.thisInterval = setInterval(function(){
        if(that.greenText){
          that.xhdz()
        }
        
      }, 500);

      that.baseURL = BaseData.baseUrl.split(0,BaseData.baseUrl.length-1)
      },
          // 所有公司
    getCompanyInfo() {
      var that = this
      companySystem.getCompanyInfo().then((res) => {
        console.log("------------------");
        this.ciIds = ""
        for (let i in res.data.data.list) {
          this.ciIds = this.ciIds + res.data.data.list[i]["id"] + ","
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
        if(this.ciIds.length >0){
          this.ciIds = this.ciIds.substring(0,this.ciIds.length-1);
        }
        console.log(this.companyNameById);
        that.mountedThis();
        console.log("---------------------");
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {};
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName) {
          this.systemNameById[this.permissionsSystemName[i].id] =
            this.permissionsSystemName[i].bigSystemName;
        }
        console.log(this.systemNameById);
      });
    },
      // 返回
      goSelect() {
      this.$router.push("/Select");
    },
        toHistory() {
      this.$router.push({
        path: "/History",
      });
    },
        //视频
    showVideo() {
      if (this.deviceInfo.videoUrl) {
        window.location.href = this.deviceInfo.videoUrl;
      } else {
        //提示信息
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
// 请求接口
      getBuildingInfo (ciIds){
        var that = this
        jQuery.ajax({
          url : this.baseURL + "Zz3dmapBuildingController/getBuildingInfo",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            ciIds: ciIds
          }),
          success : function(result) {

            // console.log(result)
            for (var i in result){

              if( result[i].geometry.type == "house" ||
                      result[i].geometry.type == "water" ||
                      result[i].geometry.type == "grassland" ||
                      result[i].geometry.type == "road"
              ){




                var lsMoBan = {
                  geometry: {
                    type: 'Polygon',
                    coordinates: [[]],
                    color:'rgba(220,220,220, 1)'
                  },
                  properties:{
                  },
                  buildingId:""
                }

                lsMoBan['geometry']['color']=result[i].geometry.color
                lsMoBan['buildingId']  = result[i].buildingId
                lsMoBan['properties']['height'] = result[i].height/3*10
                lsMoBan['ciId']= result[i].ciId
                for(var j in result[i].geometry.coordinates){
                  lsMoBan['geometry']['coordinates'][0].push([result[i].geometry.coordinates[j].lola[0],result[i].geometry.coordinates[j].lola[1]])
                }

                var deviceInfoNum = 1;
                lsMoBan['properties']['buildingType'] = result[i].geometry.type

                lsMoBan['properties']['buildingName'] = ""


                for(var ii in result[i].properties){

                  if(result[i].properties[ii].name == "deviceId"){
                    var lsjs = {}
                    lsjs["deviceId"] = result[i].properties[ii].value
                    //添加设备id 和 建筑物id到json中
                    that.buildingIdByDeviceId[result[i].properties[ii].value] = result[i].buildingId
                    for(var jj in result[i].properties[ii].deviceInfo){
                      lsjs[result[i].properties[ii].deviceInfo[jj].name] = result[i].properties[ii].deviceInfo[jj].value

                    }

                    lsMoBan['properties']["objectInfo"+deviceInfoNum] = lsjs
                    deviceInfoNum = deviceInfoNum + 1
                  }else if(result[i].properties[ii].name == "name"){
                    lsMoBan['properties']['buildingName'] = result[i].properties[ii].value
                  }else{
                    lsMoBan['properties'][result[i].properties[ii].name] = result[i].properties[ii].value

                  }
                }
                //添加有设备的建筑物id 和 建筑物信息到json中
                that.buildingInfoBybuildingId[result[i].buildingId] = lsMoBan

                that.datajs_house_big.push(lsMoBan)


              }else if(result[i].geometry.type == "roadLine"){
                //    路线的数据
                var lsMoBanlx = {
                  geometry: {
                    type: 'LineString',
                    coordinates: []

                  },
                  color:'rgba(220,220,220, 1)',
                  buildingId:""
                }

                lsMoBanlx['color']=result[i].geometry.color
                lsMoBanlx['buildingId']  = result[i].buildingId
                lsMoBanlx['ciId']= result[i].ciId
                for(var j in result[i].geometry.coordinates){
                  lsMoBanlx['geometry']['coordinates'].push([result[i].geometry.coordinates[j].lola[0],result[i].geometry.coordinates[j].lola[1]])
                }
                that.roadLineDate.push(lsMoBanlx)



              }else if(result[i].geometry.type == "visualAngle"){



                //visualAngleData = []
                var sjszcd = result[i].geometry.coordinates.length;
                var xzjd = 360/sjszcd
                var xztime = parseFloat((1/(sjszcd*2)))
                that.sjmbzoom = result[i].height<16 || result[i].height>22 ?that.sjmbzoom:result[i].height
                for(var sji in result[i].geometry.coordinates){
                  sji = parseInt(sji)

                  var poitdw = new BMapGL.Point(result[i].geometry.coordinates[sji].lola[0],result[i].geometry.coordinates[sji].lola[1])
                  if(sji == 0){
                    that.visualAngleData.push({
                      center: poitdw,
                      zoom: that.sjmbzoom,
                      tilt: that.sjmbtilt,
                      heading: 0,
                      percentage: sji * xztime
                    })
                  }else{
                    that.visualAngleData.push({
                      center: poitdw,
                      zoom: that.sjmbzoom,
                      tilt: that.sjmbtilt,
                      heading: 0-(sji*xzjd)+xzjd,
                      percentage: (sji*2-1) * xztime
                    })
                    that.visualAngleData.push({
                      center: poitdw,
                      zoom: that.sjmbzoom,
                      tilt: that.sjmbtilt,
                      heading:0-(sji*xzjd) ,
                      percentage: (sji*2)* xztime

                    })
                  }

                }
                if(sjszcd>1)
                  that.visualAngleData.push({
                    center: new BMapGL.Point(result[i].geometry.coordinates[0].lola[0],result[i].geometry.coordinates[0].lola[1]),
                    zoom: that.sjmbzoom,
                    tilt: that.sjmbtilt,
                    heading: -360+xzjd,
                    percentage: 1-xztime
                  })
                that.visualAngleData.push({
                  center: new BMapGL.Point(result[i].geometry.coordinates[0].lola[0],result[i].geometry.coordinates[0].lola[1]),
                  zoom: that.sjmbzoom,
                  tilt: that.sjmbtilt,
                  heading:-360 ,
                  percentage: 1

                })


              }else if(result[i].geometry.type == "fountain"){
                //    喷泉的数据
                for(var thispqdd in result[i].geometry.coordinates){
                  var propertiesHeightNum = parseInt(result[i].height)

                  for(var thispqi=0;thispqi<result[i].height;thispqi++){
                    that.fireworksFountainDate.push({
                      geometry: {
                        type: 'Point',
                        coordinates: [result[i].geometry.coordinates[thispqdd].lola[0],result[i].geometry.coordinates[thispqdd].lola[1]],

                      },
                      properties: {
                        height:propertiesHeightNum,
                        color:'rgba(212,242,231, 1)',
                      },

                    })
                    propertiesHeightNum--;

                  }
                }


              }else if(result[i].geometry.type == "radar" ){
                //    雷达的数据

                for(var thispqdd in result[i].geometry.coordinates){

                  that.radarDate.push({
                    geometry: {
                      type: 'Point',
                      coordinates: [result[i].geometry.coordinates[thispqdd].lola[0],result[i].geometry.coordinates[thispqdd].lola[1]],
                    },
                    properties: {
                      count: result[i].height,
                      color:result[i].geometry.color,
                    }
                  })

                }




              }

            }

// 获取设备在地图上的点位数据
        deviceInfoManagement
        .getAllDeviceTypeAboutMap()
        .then((res) => {
          console.log(res.data.data.list)

          

          for(let i in res.data.data.list){
            let lsText = "设备类型："+res.data.data.list[i]["typeName"] +'\n' +"设备id：" + res.data.data.list[i]["deviceId"]
            for(let j in res.data.data.list[i]["nameValues"]){
              lsText = lsText + '\n' + res.data.data.list[i]["nameValues"][j]["name"] + "：" + res.data.data.list[i]["nameValues"][j]["value"]
            }
            that.deviceInfoMap[res.data.data.list[i]["deviceId"]] = {
                    geometry: {
                        type: 'Point',
                        coordinates: [res.data.data.list[i].lng, res.data.data.list[i].lat],
                    },
                    properties: {
                        text: lsText,
                        textColor: "#00FF00",
                        borderColor: "#00FFFF",
                        backgroundColor: "rgba(0,0,0, 0.85)",
                        thisData:{
                          deviceId: res.data.data.list[i]["deviceId"],
                          iconurl:res.data.data.list[i]["iconurl"]
                        }
                    },
                }

            that.deviceInfoMap2[res.data.data.list[i]["deviceId"]] = {
                    geometry: {
                        type: 'Point',
                        coordinates: [res.data.data.list[i].lng, res.data.data.list[i].lat],
                    },
                    properties: {
                        text: lsText,
                        textColor: "#00FF00",
                        borderColor: "#00FFFF",
                        backgroundColor: "rgba(0,0,0, 0.85)",
                        thisData:{
                          deviceId: res.data.data.list[i]["deviceId"],
                          iconurl:res.data.data.list[i]["iconurl"],
                          display:false,
                        }
                    },
                }
                


            that.deviceInfoDate.push(that.deviceInfoMap[res.data.data.list[i]["deviceId"]]);

            console.log(that.iconBaseUrl + res.data.data.list[i]["iconurl"])
            that.deviceiconUrlDate.push({
            geometry: {
                type: 'Point',
                coordinates: [res.data.data.list[i].lng, res.data.data.list[i].lat]
            },
             properties: {
                 icon: that.iconBaseUrl + res.data.data.list[i]["iconurl"],
                 deviceId: res.data.data.list[i]["deviceId"],
             }
        })


          }



        






          // 加载别的地图数据
          that.initMapDate(that.datajs_house_big,that.roadLineDate,that.visualAngleData,that.fireworksFountainDate,that.radarDate)
        });
            

          },

        });


      },



      returnshapeLayer (wcrdata) {
        var that = this

        //深拷贝
        let thisDataYc =[JSON.parse(JSON.stringify(that.buildingInfoBybuildingId[that.buildingIdByDeviceId[wcrdata.CRTId]]))]


        //--------------------------------
        //这个存放的是视角跳转的设备和坐标点的数据
        that.sjtzArr.push({deviceId:wcrdata.deviceId,lola:thisDataYc[0].geometry.coordinates[0][0]})
        //--------------------------------


        thisDataYc[0]["DeviceInfo"] = wcrdata
        if(that.bjJzwSz['SL'+wcrdata.CRTId] == null || that.bjJzwSz['SL'+wcrdata.CRTId] == ""|| that.bjJzwSz['SL'+wcrdata.CRTId] == undefined){
          that.bjJzwSz['SL'+wcrdata.CRTId] = []
        }
        that.bjJzwSz['SL'+wcrdata.CRTId].push ( new mapvgl.ShapeLayer({
          repeat:true,
          color: 'rgba(255,0,0, 1)',
          //color: 'rgba(30,144,255, 1)',//function (data) {return data.geometry.color;}, // 颜色
          opacity:0.9,//透明度
          enablePicked: true, // 是否可以拾取
          selectedIndex: -1, // 选中项
          selectedColor: '#ffff00', // 选中项颜色
          autoSelect: true, // 根据鼠标位置来自动设置选中项
          blend: 'lighter',
          style:'windowAnimation' ,//'windowAnimation',窗户效果
          riseTime:0,
          onClick: function (e) {
            // 点击事件
            if(e.dataIndex != -1)
              that.dianjishijian(e);

          },
          data:thisDataYc,
        }))

        //用这种方式添加报警建筑物

        that.view.addLayer(that.bjJzwSz['SL'+wcrdata.CRTId][that.bjJzwSz['SL'+wcrdata.CRTId].length-1]);//报警设备所在的建筑物

      },

      //数据过来的处理函数
      dataHandler(data) {
        var that = this

        console.log(data)

           if(that.deviceInfoMap2[data.deviceId] != null && that.deviceInfoMap2[data.deviceId] != ""){
            that.deviceInfoMap2[data.deviceId]["properties"]["textColor"] = "#000000"
            that.deviceInfoMap2[data.deviceId]["properties"]["backgroundColor"] = "rgba(255,0,0, 0.85)"

                  that.deviceInfoMap2[data.deviceId]['properties']['thisData']["display"] = true
                       that.deviceInfoDate2 = []
                  for(let i in  that.deviceInfoMap2){
                    if(that.deviceInfoMap2[i]['properties']['thisData']["display"]){
                      that.deviceInfoDate2.push(that.deviceInfoMap2[i])
                    }
                  }
                  that.layerDevice2.setData(that.deviceInfoDate2)
          }



        if(data.deviceId != null && data.deviceId != "" && that.buildingIdByDeviceId[data.deviceId]!=null){
          that.returnshapeLayer(data)
          
        }else if(data.CRTId != null && data.CRTId != "" && that.buildingIdByDeviceId[data.CRTId]!=null){
          that.returnshapeLayer(data)
        }


      },

      yesUrl(id) {
        var that = this
        //删除视角跳转数组里的值
        that.sjtzArr.splice(0,1);
        //用这种方式移除报警建筑物
        that.bjJzwSz['SL'+id].forEach(element => {
          that.view.removeLayer(element)
        });
        that.bjJzwSz['SL'+id] = []

      },
      noUrl(id) {
        var that = this
        //删除视角跳转数组里的值
        that.sjtzArr.splice(0,1);
        //用这种方式移除报警建筑物
        that.view.removeLayer(id)

      },

      //报警建筑物 点击事件
      dianjishijian(e) {
        var that = this
        that.dataForSon.divAlam = e.dataItem.DeviceInfo

        console.log(that.dataForSon.divAlam.CRTId)

// 此处绑定的是设备id 删除的时候涉及到能不能删除
        // that.yesUrl(that.dataForSon.divAlam.deviceId)

// 此处绑定的是crtid 删除的时候涉及到能不能删除
        that.yesUrl(that.dataForSon.divAlam.CRTId)
        // that.noUrl(e)
      },





      //   <!--初始化展示信息数据 刷新数据-->

      //无线烟感历史记录
      rewrite1() {
        var that = this
        jQuery.ajax({
          url : this.baseURL + "smokeDetectorRecord/getSmokeDetectorProblemPage",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            draw: 2,
            start: 0,
            length: 30,
            company : "0"

          }),
          success:function (result) {
            let lssz1 = []
            for(let i in result.data){

              lssz1.push("["+result.data[i].createTime+"]"+result.data[i].companyName+"--"+result.data[i].workCenter+result.data[i].workUnit+result.data[i].problemInfo+"("+result.data[i].smokeDetectorName+")联系人:"+result.data[i].contactName1+"_联系电话:"+result.data[i].contactIphone1)
            }
            that.strS = lssz1

          }
        });
      },
      //消防报警历史记录
      rewrite2() {
        var that = this

        jQuery.ajax({
          url : this.baseURL + "YcAllHistoryController/getAllYcAllHistory",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            draw: 2,
            start: 0,
            length: 30,
            ciIds : that.ciIds

          }),
          success:function (result) {
            let lssz2 = []

            for(let i in result.data){

              let thisStrInfo = ""
              if( result.data[i].equipmentType.indexOf("用传")>-1||result.data[i].equipmentType.indexOf("报警主机")>-1){
                if(result.data[i].status == 0){
                  thisStrInfo =  '正常';
                }else if(result.data[i].status == 1){
                  thisStrInfo =  '报警';
                }else if(result.data[i].status == 2){
                  thisStrInfo =  '失联';
                }else if(result.data[i].status == 3){
                  thisStrInfo =  '主电故障';
                }else if(result.data[i].status == 4){
                  thisStrInfo =  '备电故障';
                }else if(result.data[i].status == 10){
                  thisStrInfo =  '手动火警';
                }
              }else{
                if(result.data[i].status == 0){
                  thisStrInfo =  '正常';
                }else if(result.data[i].status == 1){
                  thisStrInfo =  '失联故障';
                }else if(result.data[i].status == 2){
                  thisStrInfo =  '低值报警';
                }else if(result.data[i].status == 3){
                  thisStrInfo =  '高值报警';
                }else if(result.data[i].status == 4){
                  thisStrInfo =  '火警';
                }else if(result.data[i].status == 5){
                  thisStrInfo =  '故障(火灾报警系统故障)';
                }else if(result.data[i].status == 6){
                  thisStrInfo =  '反馈';
                }else if(result.data[i].status == 7){
                  thisStrInfo =  '屏蔽';
                }else if(result.data[i].status == 8){
                  thisStrInfo =  '启动';
                }else if(result.data[i].status == 9){
                  thisStrInfo =  '监管';
                }
              }


              lssz2.push("["+result.data[i].createTime+"]"+result.data[i].ciName+"--"+result.data[i].locationName+result.data[i].locationDescr+result.data[i].ycPlace+"_"+result.data[i].number+thisStrInfo+result.data[i].value+result.data[i].checkStatus+"(联系电话:"+result.data[i].ciContent+")")

            }

            that.strS = lssz2

          }
        });
      },
      //动环监测历史记录
      rewrite3() {

        var that = this
        jQuery.ajax({
          url : this.baseURL + "loraWanDeviceRecord/getLoraWanDeviceRecordDataTable",
          type : "POST",
          async : true,
          dataType : "json",
          data : $.param({
            draw: 2,
            start: 0,
            length: 30,
            company : that.ciIds

          }),
          success:function (result) {
            let lssz3 = []

            for(let i in result.data){
              lssz3.push("["+result.data[i].createTime+"]"+result.data[i].companyName+"--"+result.data[i].detailedLocation+result.data[i].installLocation+result.data[i].loraWanName+result.data[i].loraWanCode+result.data[i].problemInfo+"处理过程:"+result.data[i].contactIphone2+"(联系人:"+result.data[i].contactName1+"_联系电话:"+result.data[i].contactIphone1+")")
            }

            that.strS = lssz3

          }
        });

      },




      rewriteDate() {
        var that = this
        // if(that.zxffj <2){
        //     that.rewrite1()
        //     that.zxffj ++
        // }else if(that.zxffj ==2){
        //     that.rewrite2()
        //     that.zxffj ++
        // }else if(that.zxffj >=3){
        //     that.rewrite3()
        //     that.zxffj =1
        // }

        that.strS = that.strS
      },










      upup(timeThisShutDown){

        let listPanel = $('.activity ul');
        let nubcers = 0; //向上滚动top值
        let thisdsTime = new Date().getTime();
        function up(timeThisShutDown) { //向上滚动
          if(new Date().getTime()-thisdsTime>timeThisShutDown){return;}

          listPanel.animate({ //中奖结果
            'top': (nubcers - 35) + 'px'
          }, 1000, 'linear', function() {
            listPanel.css({
              'top': '0px'
            })
                    .find("li:first").appendTo(listPanel);
            that.up(timeThisShutDown);
          });
        }

        that.up(timeThisShutDown);
      },


      getUpInfo3(Ystime){
        var that = this
        setTimeout(function () {
          jQuery.ajax({
            url : this.baseURL + "YcAllHistoryController/getAllYcAllHistory",
            type : "POST",
            async : true,
            dataType : "json",
            data : $.param({
              draw: 2,
              start: 0,
              length: 20,
              ciIds : that.ciIds,
              handle: true

            }),
            success:function (result) {

              var htmlBj = "<ul>"
              var htmlBj1 = "<li><a href=\"../../yc_manage_pages/yc_all_history_check.html\" "
              var htmlBj2 =" target=\"view_window\">"
              var htmlBj3 ="</a></li>"
              let style = "style=\"color:#FF0000;\""
              for(let i in result.data){

                let thisStrInfo = ""
                if( result.data[i].equipmentType.indexOf("用传")>-1||result.data[i].equipmentType.indexOf("报警主机")>-1){
                  if(result.data[i].status == 0){
                    thisStrInfo =  '正常';
                  }else if(result.data[i].status == 1){
                    thisStrInfo =  '报警';
                  }else if(result.data[i].status == 2){
                    thisStrInfo =  '失联';
                  }else if(result.data[i].status == 3){
                    thisStrInfo =  '主电故障';
                  }else if(result.data[i].status == 4){
                    thisStrInfo =  '备电故障';
                  }else if(result.data[i].status == 10){
                    thisStrInfo =  '手动火警';
                  }
                }else{
                  if(result.data[i].status == 0){
                    thisStrInfo =  '正常';
                  }else if(result.data[i].status == 1){
                    thisStrInfo =  '失联故障';
                  }else if(result.data[i].status == 2){
                    thisStrInfo =  '低值报警';
                  }else if(result.data[i].status == 3){
                    thisStrInfo =  '高值报警';
                  }else if(result.data[i].status == 4){
                    thisStrInfo =  '火警';
                  }else if(result.data[i].status == 5){
                    thisStrInfo =  '故障(火灾报警系统故障)';
                  }else if(result.data[i].status == 6){
                    thisStrInfo =  '反馈';
                  }else if(result.data[i].status == 7){
                    thisStrInfo =  '屏蔽';
                  }else if(result.data[i].status == 8){
                    thisStrInfo =  '启动';
                  }else if(result.data[i].status == 9){
                    thisStrInfo =  '监管';
                  }
                }



                let thisStyle = thisStrInfo.indexOf("故障")==-1?style:""
                htmlBj = htmlBj + htmlBj1 + thisStyle +htmlBj2 + "["+result.data[i].createTime+"]"+result.data[i].ciName+"--"+result.data[i].locationName+result.data[i].locationDescr+thisStrInfo+result.data[i].value+htmlBj3+"\t"
              }

              document.getElementById("J_Activity").innerHTML=htmlBj + "</ul>"
              that.upup(result.data.length*1000+2000);
              that.getUpInfo1(result.data.length*1000+2000);
            }
          });
        },Ystime==1?0:Ystime>1000?Ystime:2000);

      },

      getUpInfo2(Ystime){
        var that = this
        setTimeout(function () {
          jQuery.ajax({
            url : this.baseURL + "loraWanDeviceRecord/getloraWanDeviceRealVue",
            type : "POST",
            async : true,
            dataType : "json",
            data : $.param({
            }),
            success:function (result) {

              var htmlBj = "<ul>"
              var htmlBj1 = "<li><a href=\"../../syn_lora_wan_record/lora_wan_real.html\" "
              var htmlBj2 =" target=\"view_window\">"
              var htmlBj3 ="</a></li>"
              let style = "style=\"color:#FF0000;\""
              for(let i in result.list){
                let thisStyle = result.list[i].problemInfo.indexOf("故障")==-1?style:""
                htmlBj = htmlBj + htmlBj1 + thisStyle +htmlBj2 + "["+result.list[i].createTime+"]"+result.list[i].companyName+"--"+result.list[i].detailedLocation+result.list[i].installLocation+result.list[i].loraWanName+result.list[i].problemInfo+htmlBj3+"\t"
              }

              document.getElementById("J_Activity").innerHTML=htmlBj + "</ul>"
              that.upup(result.list.length*1000+2000);
              that.getUpInfo3(result.list.length*1000+2000);
            }
          });
        },Ystime==1?0:Ystime>1000?Ystime:2000);

      },

      getUpInfo1(Ystime){
        var that = this
        setTimeout(function () {
          jQuery.ajax({
            url : this.baseURL + "smokeDetectorRecord/getSmokeDetectorProblem",
            type : "POST",
            async : true,
            dataType : "json",
            data : $.param({
            }),
            success:function (result) {

              var htmlBj = "<ul>"
              var htmlBj1 = "<li><a href=\"../../syn_smoke_record/smoke_real.html\" "
              var htmlBj2 =" target=\"view_window\">"
              var htmlBj3 ="</a></li>"
              let style = "style=\"color:#FF0000;\""
              for(let i in result.list){

                let thisStyle = result.list[i].problemInfo.indexOf("故障")==-1?style:""
                htmlBj = htmlBj + htmlBj1 + thisStyle +htmlBj2 + "["+result.list[i].createTime+"]"+result.list[i].companyName+"--"+result.list[i].workCenter+result.list[i].workUnit+result.list[i].problemInfo+htmlBj3+"\t"
              }

              document.getElementById("J_Activity").innerHTML=htmlBj +"</ul>"
              that.upup(result.list.length*1000+2000);
              that.getUpInfo2(result.list.length*1000+2000);
            }
          });
        },Ystime==1?0:Ystime>1000?Ystime:2000);

      },



      // 地图初始化

      initMapDate(datajs_house,roadLineDate,visualAngleData,fireworksFountainDate) {
        var that = this
        //datajs_house 这个是房子 水 路 和 草地的数据

        //roadLineDate 这个是路线的数据



        //fireworksFountainDate 这个是喷泉的数据

        //radarDate 这个是雷达的数据

        //visualAngleData 这个是巡视动画数据

    let zxd = [117.4003438929391300,38.9918058705706000];
        if(datajs_house.length >0 ){
            zxd = datajs_house[0].geometry.coordinates[0][0]
        }else if(that.deviceInfoDate.length>0){
          console.log(that.deviceInfoDate)
          zxd = that.deviceInfoDate[0].geometry.coordinates
        }

        this.map = initMap({
          tilt: 50,
          heading: 20,
          center:zxd,
          zoom: 18,
          style:mystyle5,
          skyColors: [
                    // 地面颜色
                    'rgba(9,18,32, 0)',
                    // 天空颜色
                    '#EC944E'
                ]
        });



        this.map.disableDoubleClickZoom();

        this.view = new mapvgl.View({
          effects: [that.bright],
          map: that.map,
        });
        this.view2 = new mapvgl.View({
          map: that.map,
        });
        this.view3 = new mapvgl.View({
          map: that.map,
        });
        this.view4 = new mapvgl.View({
          map: that.map,
        });


        //轨迹道路线
        var LineTripLayer = new mapvgl.LineTripLayer({
          color: function (data) {
            return data.geometry.color;
          }, // 颜色,
          step: 1,
          trailLength: 60,
          startTime: 0,
          data:roadLineDate
        });

        //3d建筑
        var shapeLayer = new mapvgl.ShapeLayer({
          repeat:true,
          color: function (data) {
            if(data.properties.buildingType == "house"){
              // return 'rgba(240,128,128, 1)';
              return 'rgba(30,144,255, 1)';
            }else if(data.properties.buildingType == "water"){
              return 'rgba(0,0,205, 1)';
            }else if(data.properties.buildingType == "grassland"){
              return 'rgba(12,112,53, 1)';
            }else if(data.properties.buildingType == "road"){
              return 'rgba(130,130,130,1)';
            }

            return data.geometry.color;},
          //color: 'rgba(30,144,255, 1)',//function (data) {return data.geometry.color;}, // 颜色
          opacity:0.9,//透明度
          enablePicked: true, // 是否可以拾取
          selectedIndex: -1, // 选中项
          selectedColor: '#00FF00', // 选中项颜色
          autoSelect: true, // 根据鼠标位置来自动设置选中项
          blend: 'lighter',
          style:'windowAnimation' ,//'windowAnimation',窗户效果
          riseTime:0,
          onClick: function (e) {
            // console.log(e);
            // 点击事件
            if(e.dataIndex != -1)
              that.xx(e);

          },
          data:datajs_house,
        });

        //喷泉
        var sparkLayerYhPq= new mapvgl.SparkLayer({
          color: "rgba(212,242,231, 1)",
          height: function (data) {
            return data.properties.height*2;
          },
          data: fireworksFountainDate,
        });



        //雷达
        var fanLayer = new mapvgl.FanLayer({

          size:20,
          step:0.1,
          data: that.radarDate,
          color: function (data) {
            return data.properties.color;
          },
          size: function (data) {
            return data.properties.count;
          }
        });


        // 设备数据可视化
            var layerDevice = new mapvgl.LabelLayer({
                textAlign: 'left',
                textColor: '#fc0',
                borderColor: '#666',
                backgroundColor: '#666',
                selectedColor: '#00FF00', // 选中项颜色
                autoSelect: true, // 根据鼠标位置来自动设置选中项
                padding: [2, 5],
                borderRadius: 5,
                fontSize: 12,
                lineHeight: 16,
                collides: true, // 是否开启碰撞检测, 数量较多时建议打开
                enablePicked: true,
                onClick: e => {
                    // 点击事件
                    console.log('click', e.dataItem);
                    if(e.dataItem!=null){
                   that.showDeviceInfo(e.dataItem.properties.thisData.deviceId)
                    }

                },
            });


                    // 刚加载对话框的数据 现在加载图标数据
            var layerDeviceIcon = new mapvgl.IconLayer({
              width: 100 / 4,
              height: 153 / 4,
              offset: [0, -153 / 8],
              opacity: 0.8,
              icon: 'images/marker.png',
              enablePicked: true, // 是否可以拾取
              selectedIndex: -1, // 选中项
              selectedColor: '#ff0000', // 选中项颜色
              autoSelect: true, // 根据鼠标位置来自动设置选中项
              onClick: (e) => { // 点击事件
                  console.log('click', e);
                  if(e.dataItem != null && e.dataItem.geometry.type == "Point"){
                    that.deviceInfoMap2[e.dataItem.properties.deviceId]['properties']['thisData']["display"] = !that.deviceInfoMap2[e.dataItem.properties.deviceId]['properties']['thisData']["display"]
                  }
                  that.deviceInfoDate2 = []
                  for(let i in  that.deviceInfoMap2){
                    if(that.deviceInfoMap2[i]['properties']['thisData']["display"]){
                      that.deviceInfoDate2.push(that.deviceInfoMap2[i])
                    }
                  }
                  that.layerDevice2.setData(that.deviceInfoDate2)
                  
              },
              onDblClick: e => {
                 
              },
              onRightClick: e => {
                if(e.dataItem != null){
                  that.handleRemove(e.dataItem.properties.deviceId);
                }

                  console.log('right click', e);
              }
          });

          // 报警以及点击事件的deviceInfo
          // 设备数据可视化
            that.layerDevice2 = new mapvgl.LabelLayer({
                textAlign: 'left',
                textColor: '#fc0',
                borderColor: '#666',
                backgroundColor: '#666',
                selectedColor: '#00FF00', // 选中项颜色
                autoSelect: true, // 根据鼠标位置来自动设置选中项
                padding: [2, 5],
                borderRadius: 5,
                fontSize: 12,
                lineHeight: 16,
                collides: false, // 是否开启碰撞检测, 数量较多时建议打开
                enablePicked: true,
                onClick: e => {
                    // 点击事件
                    if(e.dataItem!=null){
                   if(that.deviceInfoMap2[e.dataItem.properties.thisData.deviceId]['properties']['thisData']["display"]){
                                        that.showDeviceInfo(e.dataItem.properties.thisData.deviceId)
                  that.deviceInfoMap2[e.dataItem.properties.thisData.deviceId]["properties"]["textColor"] = "#00FF00"
                  that.deviceInfoMap2[e.dataItem.properties.thisData.deviceId]["properties"]["backgroundColor"] = "rgba(0,0,0, 0.85)"

                   }


                    }

                },
            });

            that.view4.addLayer(that.layerDevice2);
            that.layerDevice2.setData(that.deviceInfoDate2);
            


            that.view3.addLayer(layerDeviceIcon);
            layerDeviceIcon.setData(that.deviceiconUrlDate);





            if(that.layerDeviceDisplay){
             that.view2.addLayer(layerDevice);
            layerDevice.setData(that.deviceInfoDate);
              
            }





        // 设备数据可视化






        that.view.addLayer(shapeLayer);//3D建模
        that.view.addLayer(LineTripLayer);//轨迹线图
        that.view.addLayer(sparkLayerYhPq);//喷泉
        that.view2.addLayer(fanLayer);//雷达

        that.map.setDefaultCursor('default');//默认鼠标
        that.map.setDisplayOptions({poiText: false});//影藏地点名称
        //开始播放巡视动画
        that.startViewAnimation(visualAngleData);



        // setTimeout("initBjDate()",5000);


      },


    //展示点位信息
    showDeviceInfo(deviceInfoId) {
      // TODO 展示点位信息
      deviceInfoManagement.getDeviceInfoById(deviceInfoId).then((res) => {
        this.deviceInfo = res.data.data.deviceInfoVo;
      });
      deviceInfoManagement
        .getDeviceInfoValueByDeviceId(deviceInfoId)
        .then((res) => {
          this.deviceValueInfoList = res.data.data.list;
        });
      this.deviceInfoDialogVisible = true;
    },
    //关闭设备详情的时候清空设备信息详情
    clearDeviceInfo() {
      this.deviceInfo = {};
    },


      xx(e) {
        var that = this
        console.log(e)

        var lsStr = ""
        var x = 1;
        for(var iStr in e.dataItem.properties){

          if(!(iStr=="height"||iStr=="buildingType"||iStr=="buildingName"||iStr=="name")){

            if(iStr.length>10 && iStr.substring(0,10)==="objectInfo"){
              var tpgaodu =  Object.keys(e.dataItem.properties[iStr]).length* 20


              tpgaodu = tpgaodu>100?100:tpgaodu
              lsStr =lsStr + "<div>"+"<text style='color: #0BB20C'>"+iStr+"</text>"+"</div>"+"<div style='height: "+tpgaodu+"px;\n" +
                      "    width: 100px;\n" +
                      "    position: absolute;\n" +
                      "    left: 270px;'> <button onClick='toCrtOnly(\""+e.dataItem.properties[iStr]["deviceId"]+"\")'>点击查看crt</button> </div>"
              x++
              for(var lsi in e.dataItem.properties[iStr]){
                let thislsi = lsi
                if(lsi == "deviceId")
                  thislsi = "objectId"
                lsStr =lsStr + "<div>&nbsp;&nbsp;&nbsp;&nbsp;"+thislsi+":"+"<text style='color: #6b7d86'>"+e.dataItem.properties[iStr][lsi]+"</text>"+"</div>"
                x++
              }
            }else{
              lsStr =lsStr + "<div>"+"<text style='color: #0BB20C'>"+iStr+"</text>"+":"+e.dataItem.properties[iStr]+"</div>"
              x++
            }

          }


        }

        if(x>1)
          that.dataForSon.div = lsStr
        that.startSubmitwindows()
        // that.map.openInfoWindow(new BMapGL.InfoWindow(lsStr, {
        //     width: 400,
        //     height: 25*x,
        //     title: " "+e.dataItem.properties.buildingName
        // }),  new BMapGL.Point(e.dataItem.geometry.coordinates[0][1][0], e.dataItem.geometry.coordinates[0][1][1])); // 开启信息窗口


      },


      startViewAnimation(visualAngleData){
        var that = this

        var opts = {
          duration: that.sjztime*1000,     // 设置每次迭代动画持续时间
          delay: that.dhystime * 1000,         // 设置动画延迟开始时间
          interation: 'INFINITE'        // 设置动画迭代次数
        };

        that.animation = new BMapGL.ViewAnimation(visualAngleData, opts);        // 初始化动画实例
        that.animation.addEventListener('animationstart', function(e) {        // 监听动画开始事件
          console.log('start');
          that.DhSjStatus = true
        });
        that.animation.addEventListener('animationiterations', function(e) {   // 监听动画迭代事件
          console.log('onanimationiterations');
        });
        that.animation.addEventListener('animationend', function(e) {        // 监听动画结束事件

          console.log('end');
        });
        that.animation.addEventListener('animationcancel', function(e) {       // 监听动画中途被终止事件
          that.DhSjStatus = false
          console.log('cancel');
        });

        //map.startViewAnimation(animation);         // 开始播放动画

        //map.cancelViewAnimation(animation);         // 强制停止动画







      },

      sfStartdhsj(){

        var that = this
        if(that.DhSjStatus){
          that.map.cancelViewAnimation(that.animation);
        }else{
          that.map.startViewAnimation(that.animation);
        }
      },

      sfStartzdtz(){
        var that = this
        that.startzdtz = !that.startzdtz
        alert("警情自动跳转:"+(that.startzdtz?"已开启":"已关闭"))
      },




      typewriter(divId,time) {
        var that = this

        that.divIds[divId] = 1;
        setTimeout(function() {that.divIds[divId] = 0;},time+1000);

        var $ele = document.getElementById(divId);
        var str = $ele.innerHTML,
                progress = 0;
        $ele.innerHTML = '';
        var timer = setInterval(function() {
          var current = str.substr(progress, 1);
          if (current == '<') {
            progress = str.indexOf('>', progress) + 1;
          } else {
            progress++;
          }
          $ele.innerHTML = str.substring(0, progress) + (progress & 1 ? '_' : '');
          if (progress >= str.length) {
            clearInterval(timer);
          }
        }, 75);
      },

      //传入 内容 div的id 时间

      zwcCl(str,divId,time){
        var that = this

        document.getElementById(divId).innerHTML = str
        that.typewriter(divId,time);

      },


      mapsjtz(point,zoom) {
        var that = this
        that.map.flyTo(point,zoom)


      },
      xhdz() {
        var that = this


        for(var i in that.divIds){
          if(that.divIds[i]  == 0){
            if(that.dqStr >= that.strS.length){
              //这里重新请求另一个接口 并且将数据赋值给strS
              that.rewriteDate();
              that.dqStr = 0
            }

            if (that.strS.length>0){
              that.zwcCl(that.strS[that.dqStr],i,(that.strS[that.dqStr].length/5+1)*1000);
              that.dqStr ++;
            }


          }
        }


        //    这个是看时候需要自动跳转
        if(that.startzdtz){


          if(that.sjtzArr.length>0){
            //如果视角正在动 则暂停视角运动
            if(that.DhSjStatus){
              // 这个是暂停视角动画
              that.map.cancelViewAnimation(that.animation);

            }
            //    这个是循环的去跳转到数组里面的视角

            if(((that.thisSjId != that.sjtzArr[0].deviceId) && (that.thisSjId!="init")) || that.thisSjId=="init"){

              that.thisSjId = that.sjtzArr[0].deviceId
              that.mapsjtz(new BMapGL.Point(that.sjtzArr[0].lola[0], that.sjtzArr[0].lola[1]),19);

            }


          }






        }



      },
      shutdownSubmitwindows(){
        var that = this
        that.submitWindows = false

        that.dataForSon.div = ""
        that.dataForSon.divAlam  = null
      },
      startSubmitwindows(){
        var that = this
        that.submitWindows = true
      },


    },

    destroyed(){
      var that = this
      clearTimeout(this.thisInterval)
      // 给home通知组件已经不是这个组件
      that.$emit("routerIsmapBuidingHomeTFFunction",false)


    },



  }

</script>
<style lang='scss' scoped>
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  #map_container {
    width: 100%;
    height: 96%;
    margin: 0;
  }
  .sel1{
    width: 260px;
  }

  .shuaxin {
    position: fixed;
    left: 2.5%;
    // top: 0.5;
    z-index: 9999;
  }
  .shuaxin1 {
    position: fixed;
    right: 2.5%;
    // top: 0.5;
    z-index: 9999;
  }
  .shuaxinbt {
    position: fixed;
    margin-left: 0.1%;
    margin-top: 0.5%;
    z-index: 999;
  }
  a {
    display:block;
    line-height:18px;
    text-decoration:none;
    color: #ff9f00;
    font-family:Arial;
    font-size:15px;
  }
  a:hover {
    text-decoration:underline;
  }
  .shell {
    width:500px;
    position: fixed;
    margin-left: 70%;
    margin-top: 0.0%;
    z-index: 999;
  }

  .shell2 {
    color: #00ff00;
    font-size: 15px;
  }
  #div1 {
    height:100%;
    overflow:hidden;
  }





  #code p:nth-child(odd) {
    color:rgb(157,35,228);
  }
  #code p:nth-child(even) {
    color:red;
  }
  #code p:nth-child(1) {
    color:red;
  }
  #code p:nth-child(2) {
    color:rgb(240,27,134);
  }
  #code p:nth-child(3) {
    color:rgb(173,230,203);
  }
  #code p:nth-child(4) {
    color:rgb(40,228,65);
  }


  .activity {
    height:100%;
    margin:0 auto;
    position:relative;
    overflow:hidden;
  }
  .activity ul {
    top:-15px;
    padding:0;
    color:#666;
    position:relative;
  }
  .activity li {
    height:34px;
    padding:0;
    font-size:14px;
  }

  .submitWindowsViewClass{
    z-index: 999;
    width: auto;
    min-width: 22rem;
    padding: .75rem 1.25rem;
    margin-left: 20rem;
    margin-top: 10rem;
    position: fixed;
    top: 1rem;
    color: #000;
    // display: none;


  }
  .displayNone{
    display: none;
  }
  .displayBlock{
    display: block;
  }

  .el-dialog__title{
  font-size: 26px;
}
.el-form-item {
  margin-bottom: 0px;
}
.el-row {
  margin-bottom: 20px;
}
.close {
  color: #fff;
  position: absolute;
  right: 1%;
  font-size: 38px;
  font-weight: 300;
  align-self: center;
  cursor: pointer;
}
#overburden {
  position: absolute;
}

#tempText {
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
  margin-top: 250px;
}
#crt {
  width: 1300px;
}
.item-form {
  display: flex;
  flex-wrap: wrap;
  height:50vh;
  overflow-y:scroll;
}
.item-card {
  text-align: center;
  margin: 0.5%;
  width: 24%;
  height: 130px;
  background-color: rgb(194, 228, 240);
  line-height: 35px;
  font-size: 18px;
}
.item-card:hover{
  box-shadow: 1px 1px 10px rgb(114, 113, 113);
}

.green{
  background-color: lightgreen;
}
.yellow{
  background-color: rgb(246, 246, 188);
  /* animation: breathing 1s linear infinite alternate; */

}
.red{
  background-color: rgb(252, 105, 105);
  animation: breathing 0.7s linear infinite alternate;
}
@keyframes breathing {
            0% {
                transform: scale(0.9)
            }
            100% {
                transform: scale(01)
            }
        }
.gery{
  background-color: rgb(204, 207, 204);
}
.blue{
  background-color: lightblue;
}
.el-dialog__title{
  font-size: 19px !important;
}

</style>