<!-- 展示设备信息的界面 -->
<template>
  <div class="container-fluid panel-custom" style="background:rgba(255,255,255,0.9) ;width:500px;height:400px;overflow-x:auto;">
    <div style="text-align: right"><el-button type="mini" circle @click="shutDown()">关闭</el-button></div>
    <div id="divThis" v-html="dataForSon.div">
    </div>

    <div id="divAlam" :class="{displayBlock:!(this.dataForSon.divAlam == undefined||this.dataForSon.divAlam == null),displayNone:(this.dataForSon.divAlam == undefined||this.dataForSon.divAlam == null),}">
      {{dataForSon.divAlam}}
      <button @click="toCrt()">点击查看设备crt</button>
    </div>

  </div>
</template>

<script>
  export default {
    data() {
      return {

      };

    },

    props: {
      dataForSon: {
        div: "",
        divAlam: null
      }
    },

    components: {},

    computed: {},

    mounted() {
      var that = this
      window.toCrtOnly = that.toCrtOnly
    },

    methods: {
      shutDown() {
        this.$parent.shutdownSubmitwindows();
      },

      toCrt() {
        var that = this

        let routeData = this.$router.resolve({
          path: "/CRTShow",
          query: { //要传的参数
            CRTId: that.dataForSon.divAlam.CRTId,
            deviceId: that.dataForSon.divAlam.deviceId
          }
        })
        window.open(routeData.href, '_blank')

        //       this.$router.push({
        //   name:"History",
        //   params:{
        //     resInfo:that.dataForSon.divAlam
        //     }
        // })

      },
      toCrtOnly(crtId) {
        var that = this

        let routeData = this.$router.resolve({
          path: "/CRTShow",
          query: { //要传的参数
            CRTId: crtId,
            deviceId: crtId
          }

        })
        window.open(routeData.href, '_blank')

      }
    }
  }
</script>

<style lang="scss" scoped>
.el-button {
  color: #f56c6c;
}
  .displayNone {
    display: none;
  }

  .displayBlock {
    display: block;
  }
</style>
