import request from '../../../utils/request'

let baseURL =  request.baseURL
export default {
    deleteDeviceLoLa(id) {
        return request({
            url: `/Zz3dmapBuildingController/deleteDeviceLoLa?deviceId=${id}`,
            method: 'get'
        })
    },
    getBaseURL(){
        return baseURL;
    },
}